.ant-layout.login-layout {
  background: #fff;
  height: 100%;
}

.login-layout-content {
  padding-top: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}