.ant-table-tbody > tr > td {
  background-color: @table-body-bg;
  border-bottom: none;
}

.ant-table table {
  border-spacing: 0px 5px;
}

.ant-table-expanded-row {
  .ant-table-cell {
    background-color: @table-expanded-row-bg !important;
  }
}

.device-evses-table, .charging-group-evses-table {
  .ant-table {
    margin: 0 !important;

    table {
      border-spacing: 0 !important;
    }

    .ant-table-thead {
      th {
        background-color: @table-expanded-row-header-bg !important;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        background-color: white !important;
      }
    }
  }
}