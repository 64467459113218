@import '~antd/dist/antd.less';
@import '~antd/dist/antd.compact.less';
@import '../../_styles/variables.less';

.formBtn {
  margin-right: 5px !important;
}

.helpBtn {
  background-color: @btn-primary-bg !important;
  color: white !important;
  border: none !important;
  box-shadow: none !important;
}