@import '~antd/dist/antd.less';
@import '~antd/dist/antd.compact.less';
@import './variables.less';
@import './table.less';

@font-face {
  font-family: 'Oscine App';
  src: url('https://ea-font-files.s3.amazonaws.com/Oscine_A_Rg.ttf') format('truetype');
}

body {
  font-family: 'Oscine App', sans-serif;
}

.ant-tabs-content {
  margin-top: @margin-lg;
}

.ant-descriptions-item-label {
  font-weight: bold;
}

.header-button-group {
  top: 2rem;
  right: 2.5rem;
  position: absolute;
  display: flex;

  .action-button {
    margin-left: 15px;
  }

  .help-button {
    background-color: @btn-primary-bg;
    color: white;
    margin-left: 15px;
    border: none !important;
    box-shadow: none;

    .anticon {
      font-size: 24px !important;
    }

    &:hover {
      color: @btn-primary-bg;
      background-color: unset;
    }
  }

  &.charging-group {
    top: 3rem;
  }
}