.formItem {
  margin: 10px 0 !important;
  background-color: #f9fafc;
  padding: 10px !important;
}

.formItemContent {
  display: flex;
  flex-direction: column;

  & > button {
    width: fit-content;
  }
}

.deleteBtn {
  margin-top: 10px;
  width: fit-content;
}