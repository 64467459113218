.go-back-button {
  width: 100%;
  display: flex;
  align-items: baseline;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }

  Button {
    background: @button-background;
    border-color: @button-background;
    margin: 1rem 1rem 1rem 0;

    &:hover {
      background: @button-background;
      border-color: @button-background;
      color: inherit;
    }
  }

  h3 {
    font-weight: bold;
  }
}

@button-background: #edf0f9;