.search-filter-form {
  margin: auto -1px;
  overflow: hidden;
  .search-form {
    padding: 30px;
  }
  .button {
    &:nth-of-type(1) {
      margin-right: -1px;
    }
  }
}