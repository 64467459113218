@font-face {
  font-family: "Oscine App";
  src: url("https://ea-font-files.s3.amazonaws.com/Oscine_A_Rg.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Oscine App", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
