@import '_styles/theme.less';

.remote-action-menu {
  &.ant-dropdown-menu {
    padding: 0px 10px;
    padding-top: 10px;
  }
  .ant-btn {
    height: 40px;
    text-align: left;
    &:hover {
      color: #009F66;
      background-color: #E8F4EF;
    }
  }
  .ant-dropdown-menu-item {
    padding: 0;
    padding-bottom: 10px;
    &:hover {
      background-color: #ffffff;  
    }
  }
  .ant-dropdown-menu-item-active {
    background-color: #ffffff;
  }

}

.remote-action-confirm-modal {
  .ant-modal-footer {
    padding: 0;
  }

  .ant-btn {
    border-radius: 0;
  }
}