.ant-layout-sider {
  overflow: auto !important;
  height: 100vh !important;
  position: fixed !important;
  left: 0 !important;

  .ant-menu-inline {
    padding-bottom: 50px;
  }
}

.ant-menu.ant-menu-inline-collapsed {
  max-width: 100% !important;

  .ant-menu-item {
    height: 100% !important;

    .ant-menu-title-content {
      display: none !important;
    }
  }
}

.ant-menu {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-content: center !important;
  max-width: 85% !important;
}

.ant-menu-item {
  display: block !important;
  background-color: #4a526e;
  text-align: center;
  margin: 8px 15px !important;
  padding: 10px 0px !important;
  height: 100% !important;
  border-radius: 5px;

  &.ant-menu-item-selected {
    .ant-menu-title-content {
      color: black !important;
    }
  }
}
