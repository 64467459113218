@import '_styles/theme.less';

.deviceContainer {
  .ant-tabs-content-holder {
    margin-bottom: @margin-lg
  }

  .ant-tabs-tabpane {
    overflow: auto;
  }
}
.deviceDetailContainer {
  min-height: 80vh;
  width: 100%;
  margin: auto;
}

.descriptionDetailContainer,
.stateKeysContainer {
  text-align: left;
}

.portsTable {
  margin-bottom: @margin-lg;
}

.chargingGroupDetailView {
  margin-bottom: @margin-lg;
}

// so that the status tag sits on the baseline
.ant-descriptions-item.description-status-tag {
  .ant-descriptions-item-container .ant-descriptions-item-content {
    display: block;
  }
}
.formBtn {
  margin-right: 5px !important;
}

.deviceLiveLogs {
  .deviceLiveLogsContainer {
    background-color: @menu-dark-item-active-bg;
    height: 55vh;
    overflow-y: auto;
    padding: 10px;
  }

  .toggleButton {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .message-item {
    &.server {
      color: @blue-6;
    }

    &.charger {
      color: @red-6;
    }
    padding-bottom: 10px;
  }

  .server-example {
    color: @blue-6;
  }

  .charger-example {
    color: @red-6;
  }
}

.disabled-row {
  pointer-events: none;
  opacity: 0.5;
}

.export-raw-logs {
  display: flex;
  justify-content: flex-end;
}

.configuration-item {
  padding: 5px;
  margin: 5px;
  background-color: @table-body-bg;
  vertical-align: center;
  align-items: center;

  .ant-col {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}