.logo {
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 16px;
}

.site-layout {
  background: #fff !important;
  padding: 30px 50px;

  .ant-layout-content {
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .site-layout-background {
    background: #fff;
  }
  .site-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }

  .site-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 0 !important;
    text-transform: capitalize;
  }
}
