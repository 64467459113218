.export-csv-button {
  .ant-btn {
    background: #F1F4F9;
    border-color: #F1F4F9;
    border-radius: 5px;
    margin: 1rem 0;

    &:hover {
      background: #0E193F;
      border-color: #0E193F;
      color: #FFFFFF;
    }
  }
}
