.delta-detail-container {
  height: 100%;

  .delta-item {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    pre {
      width: 100%;
      white-space: pre-wrap;
    }
  }

  .detal-detail {
    width: 100%;
    display: flex;
    flex: 1;
    overflow-y: auto;
  }
}