@import '_styles/theme.less';

.clickable-row {
  cursor: pointer;
}

.charging-group-form {
  margin-bottom: 32px !important;
}

.edit-form-label {
  padding: 0px !important;
  margin: 0px !important;
}